/* style.css */

body {
  background-color: #0d0c1b;
  font-family: Arial, sans-serif;
  color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.form-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 25px;
  border-radius: 10px;
  background-color: #1a1a2e;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #282846;
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  background: linear-gradient(to right, #00c6ff, #0072ff);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  padding: 10px 0;
  font-weight: bold;
  text-shadow: 0px 4px 8px rgba(0, 150, 255, 0.3);
  position: relative;
}

.form-title::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -8px;
  width: 60%;
  height: 2px;
  background-color: rgba(0, 150, 255, 0.6);
  border-radius: 5px;
}

input[type="text"] {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #4a4a6a;
  border-radius: 8px;
  background-color: #212135;
  color: #ffffff;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

input[type="text"]::placeholder {
  color: #9e9b9b;
}

input[type="text"]:focus {
  outline: none;
  border-color: #00aaff;
  background-color: #2a2a3d;
  box-shadow: 0 0 6px rgba(0, 170, 255, 0.4); /* Hafif parlak mavi dış gölge */
  transform: scale(1.01); /* Hafif büyüme efekti */
}

button[type="submit"] {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.1s ease;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.3);
}

button[type="submit"]:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

button[type="submit"]:active {
  background-color: #004080;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  transform: scale(0.98);
}

button[type="submit"]:disabled {
  background-color: #779dd6;
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
}


.notification {
  padding: 20px;
  margin-top: 15px;
  border-radius: 10px;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: #333;
  position: relative;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-left: 4px solid; /* Renk duruma göre ayarlanacak */
}

.notification.success {
  background-color: #28a745;
  border-left-color: #1b5e20;
  display: block;
}

.notification.error {
  background-color: #dc3545;
  border-left-color: #8b1c2a;
}

.notification .icon {
  font-size: 20px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification p {
  margin: 0;
  line-height: 1.5;
  color: #f0f0f0;
}

.user-info {
  background-color: #2a2a3d;
  border: 1px solid #3c3c54;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  color: #d1d1e0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

